.grid-container {
    /* display: flex; */
    /* grid-template-columns: auto auto auto; */
    background-color:white;
    /* padding: 10px; */
 width: 1000px; 
 color: black;
 padding: 20px;

  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border-right: 1px solid rgba(0, 0, 0, 1);
    border-bottom: 2px solid rgba(0, 0, 0, 1);
    padding: 20px  20px 0px 20px;
    
  }
  .pdfinput {
    width: 100%;
    padding: 0px 20px;
    margin: 0px 0;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid rgb(8, 8, 8);
    outline: none;
  }
  #input-field {
    width: 50px;
    font-size: 16px;
    padding: 5px;
    box-sizing: border-box;
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: auto !important;
  }