/* Global Styling*/
* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #eff3fd;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #0b2558;
  border-radius: 14px;
  border: 3px solid var(--primary);
}
/* input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  background-color: transparent !important;
  caret-color: white !important; 
} */

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(239, 243, 253);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
p {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
input {
  margin: 0;
  font-family: "Poppins", sans-serif;
}
button {
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #898787 inset !important;
  /* -webkit-box-shadow: 0 0 0 30px white inset !important; */
}
/* For fixing white background of phone input 2 package due to browser credentials issue in auth page*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 10000000s ease-in-out 0s !important;
}

/* For fixing white background of phone input 2 package due to browser credentials issue in auth page*/
.emailComp input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff !important;
}

/*Overwriting styling of flags component in react input-2 in auth pages */
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent !important;
  border: none !important;
  border-radius: 3px 0 0 3px;
  color: #fff !important;
}

.react-tel-input .flag-dropdown .selected-flag {
  pointer-events: auto !important; /* Enable clicking */
}

.react-tel-input .flag-dropdown:hover .selected-flag {
  background-color: transparent !important;
  cursor: default !important;
}

/*Overwriting styling of React input-2 in auth pages */
.react-tel-input .form-control {
  background: transparent !important;
  border: none !important;
  border-radius: 5px;
  line-height: 0px !important;
  height: 50px !important;
  width: 300px;
  outline: none;
  color: #fff !important;
}

/*Overwriting styling of React input-2 in auth pages (To overwrite white background of flag icon)*/
.react-tel-input .selected-flag:hover {
  background: #0b2558 !important;
}

/*Overwriting styling of React input-2 in auth pages (To overwrite white background of flag icon)*/
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

/*Overwriting styling of React input-2 in auth pages (Alignment fixing of flag and number when dropdown is open)*/
.react-tel-input .country-list .country {
  padding: 7px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
}

/* For react-signature package */
.signatureCanvas {
  width: 100%;
  min-height: 220px;
  background: #eff3fd;
  border: 2px solid #1b1b1b;
  border-radius: 5px;
}

/* For react pro sidebar package menu item class  */
.css-wx7wi4 {
  margin-right: 0px !important;
}

/* N/A */
.css-z5rm24 {
  background-color: transparent !important;
}

/* N/A */
.css-bz5ng3-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 1px solid #c2c2c2 !important;
}

/*
   [For  Development]
   MUI Field Input class overwritten:
   - Apply border styles when focused
   - For errors, set border-color to red
*/
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid #869497 !important;
}

/* N/A */
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #1b1b1b !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

/*
   [For Production]
   MUI Field Input class overwritten for login/signup screen:
   - Apply border 
   - For errors, set border-color to red
*/
.css-1rqoqxy::before {
  border-bottom: 1px solid #fff !important;
}

/* N/A */
.css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #869497 !important;
  border-radius: 10px;
}

/* N/A */
.css-1ald77x.Mui-focused {
  color: #1b1b1b !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

/* 
   [For  Development]
   MUI DatePicker Styles Overwriting */
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 12% !important;
  font-family: "Poppins", sans-serif !important;
}

/* 
   [For  Production]
   MUI DatePicker Styles Overwriting */
.css-igs3ac {
  border-color: #869497 !important;
  border-radius: 10px !important;
  border-width: 1px !important;
}

/* N/A */
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #1b1b1b !important;
  font-weight: 600 !important;
}

/* N/A */
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 9px !important;
  background: rgba(239, 243, 253, 1) !important;
}

/* 
  [For Production]
  MUI Date picker  */
.css-165mck1 {
  width: 32% !important;
  font-family: "Poppins", sans-serif !important;
}

/* 
  [For  Development]
  MUI Tab Styles Overwriting (for client dashboard) */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-width: 12% !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  font-family: "Poppins", sans-serif !important;
}

/* 
  [For  Development]
  MUI INPUT Styles Overwriting (for Input With Adornment) */
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #869497 !important;
  border-radius: 10px !important;
  border-width: 1px !important;
}

/* 
  [For Production]
  MUI INPUT Styles Overwriting (for Input With Adornment) */
.css-1ufn0jl.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #869497 !important;
  border-radius: 10px !important;
  border-width: 1px !important;
}

/* 
  [For Production]
  MUI INPUT Styles Overwriting (for Sign in InputTag With Adornment) */
.css-1a6giau {
  color: #869497 !important;
}

/* 
  [For Production]
  MUI SELECT Styles Overwriting (for Select Month in add rental page ) */
.css-18m8r0v {
  color: #869497 !important;
}

/* 
  [For Production]
  MUI SELECT Styles Overwriting (for Sign in InputTag With Adornment) */
.css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #869497 !important;
}

/* N/A */
.css-1pnmrwp-MuiTypography-root {
  color: #1b1b1b !important;
}

/* 
  [For Development]
  Client Module MUI TABS */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  border-bottom: 1px solid #0b2558 !important;
  color: #0b2558 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

/* 
  [For Development]
  Client Module MUI TABS */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
  background-color: #0b2558 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-size: 16px !important;
}

/* 
  [For Production]
  Client Module MUI TAB when selected */
.css-1q2h7u5.Mui-selected {
  color: #fff;
  background-color: #0b2558;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-width: 10% !important;
}

/* 
  [For Production]
  Client Module MUI TAB when selected */
.css-1q2h7u5.Mui-selected {
  color: #fff !important;
}

/* 
  [For Production]
  MUI Password fields */
.css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #869497 !important;
  border-radius: 10px !important;
  border-width: 1px !important;
}

/* For date picker color  */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #869497 !important;
}

/* For mui accordion open accordion */
.css-o4b71y-MuiAccordionSummary-content {
  font-size: 16px !important;
  font-weight: 500 !important;
}

/* 
  [For Development]
  MUI Accordion Opened Body styling */
.css-15v22id-MuiAccordionDetails-root {
  padding: 25px 16px 16px !important;
  background: #fff !important;
}

/* 
  [For Production]
  MUI Accordion Opened Body styling */
.css-u7qq7e {
  padding: 25px 16px 16px !important;
  background: #fff !important;
}

/* 
  [For Development]
  For sign of MUI Input Adornment */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: #869497 !important;
}

/* 
  [For Production]
  For sign of MUI Input Adornment */
.css-18m8r0v {
  color: #1b1b1b !important;
}

/* 
  [For Production]
   For sign of MUI Input Adornment */
.css-1x5jdmq {
  color: #1b1b1b !important;
}

/* 
  [For Production]
  Client Module MUI TABs */
.css-1q2h7u5 {
  background-color: #0b2558;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-width: 10% !important;
  border-bottom: 1px solid #0b2558 !important;
  color: #0b2558 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize !important;
  white-space: nowrap !important;
}
.css-12w9als {
  white-space: wrap !important;
}
/* 
  [For Production]
  Date input error class */
.css-1ald77x.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
}

.avtive {
  background-color: #00adef !important;
  color: #fff;
}

.message-box {
  clip-path: polygon(
    29% 5%,
    100% 5%,
    100% 100%,
    76% 100%,
    1% 100%,
    1% 16%,
    0 5%
  );
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.error-message {
  color: red !important;
}
/* .queue-table .MuiDataGrid-withBorderColor{
   display: none !important;
} */
.assign-staff
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 30px !important;
  padding: 2.5px 14px !important;
}
@media (max-width: 1200px) {
  .sm-250 {
    height: 250px;
  }
}
